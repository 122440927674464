.is-granted-edit-inline {
    padding-top: 30px;

    .highlighted {
        &:before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: block !important;
            right: 0;
            bottom: 0;
            background: rgba(245,94,94,.6) !important;
            z-index: 888 !important;
            opacity: 1;
        }
    }

    .edit-inline-button {
        @include vcenter;
        position: fixed;
        left: 0;
        z-index: 1;
        background-color: var(--audreys-color);
        cursor: pointer;
        height: 40px;
        width: 40px;
        font-size: 24px;
        line-height: 40px;
        text-align: center;
        border-radius: 0 10px 10px 0;
        cursor: pointer;

        button {
            color: var(--dark-color);
            font-weight: 300;
        }
    }

    .inline_edit_link {
        display: none;
        position: absolute;
        top: auto;
        left: auto;
        bottom: 0;
        right: 2%;
        z-index: 9999;
        text-decoration: none;
        padding: 0 !important;
        line-height: 35px !important;
        box-shadow: 0 5px 10px rgba(#000, 0.2);
        background-color: var(--audreys-color) !important;
        width: 30px !important;
        height: 30px !important;
        border-radius: 50px;
        font-size: 20px !important;
        line-height: 32px;
        text-align: center;
        color: var(--light-color) !important;

        i:before {
            color: var(--light-color);
            font-size: 16px;
            line-height: 30px;
        }

        &:hover {
            color: var(--audreys-color-light);
            background-color: var(--dark-color);
        }
    }

    .button-edit-inline {
        border: none;
        background-color: transparent;

        :focus {
            outline: none;
        }
    }

    .widget-social-icons,
    .main-navigation{
        .inline_edit_link {
            position: absolute !important;
            left: auto !important;
            right: 0;

        }
    }
    .widget-links .inline_edit_link {
        position: absolute !important;
        top: 50%;
        margin-top: -12px;
        width: 25px !important;
        height: 25px !important;
        line-height: 20px !important;

        &:before {
            font-size: 16px;
        }
    }

    [class*="-editinline"] {
        position: relative;
    }

    .block-content .reviews-block--content-editinline {
        left:20px !important;
    }

    .header .menu-main .inline_edit_link,
    .menu-main .inline_edit_link {
        top: 10%;
        left: 50%;

        @media(--min-desktop-large) {
            top: 0;
            left: 0;
            transform: translateX(-50%);
        }
    }

    .header .inline_edit_link {
        top: 10%;
        left: 50%;
        transform: translateX(-50%);

        @media (--min-desktop) {
            top: 0;
            left: 0;
        }
    }

    .header-intro .inline_edit_link {
        left: auto;
        right: 20%;
    }

    .header-experiences .inline_edit_link {
        right: 45%;
        top: 0;
        left: auto;

        @media(--min-desktop) {
          right: 25%;
          top: 15%;
        }
    }

    .customize-header .inline_edit_link {
        right: 7%;
    }

    .customize-cta .inline_edit_link {
        left: 240px;
        top: 0;

        @media(--min-desktop) {
            right: 45%;
            top: 0;
        }
    }

    .home-experience--header .inline_edit_link {
        top: 0;

        @media(--min-desktop) {
            right: 45%;
            top: 10%;
        }
    }

    .experience-list--item .inline_edit_link {
        top: 10%;
        right: 10%;
    }

    .travel-main .inline_edit_link {
        top: 20px;

      @media(--min-desktop) {
          top: 100px;
      }
    }

    .travel-info-advisor .inline_edit_link {
        top: 30px;
        right: 0;
    }

    .blog-menu--list .inline_edit_link {
        top: 10%;
    }

    .blog-container .inline_edit_link {
        top: 30px;
        right: 5%
    }

    .experience-detail--activity .inline_edit_link,
    .experience-detail--features-item .inline_edit_link {
        top: 10%;
        right: 40%;
    }

    .footer-content-box .inline_edit_link {
        top: 0;
    }

}

.icon-edit-inline{
    &:before {
        width: 25px;
        height: 28px;
        display: inline-block;
        color: var(--light-color) !important;
        content:"";
        font-size: 20px;
        background-image: url(/img/edit-inline.svg);
        background-size: contain;
        background-position: 50%;
        background-repeat: none;
    }
}

.edit_default_link {
    position: absolute;
    bottom: 20px;
    top: auto;
    left: auto;
    right: 20px;
    display: none;
}

.admin-nav {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 30px;
    z-index: 10;
    background-color: var(--audreys-color);
    font-size: 14px;
    line-height: 30px;
    overflow: hidden;
    z-index: 1000;
}

.admin-nav-item {
    float: left;
    cursor: pointer;

    button {
        background-color: var(--audreys-color-light);
    }

    a,
    button {
        display: block;
        color: var(--dark-color);
        font-weight: 400;
        line-height: 30px;
        margin: 0;
        padding: 0 10px;
        text-decoration: none;
    }

    img{
        max-height: 40px;
        margin-top: -5px;
    }

    .admin{
        padding: 0;
    }

    &:hover {
        background-color: var(--audreys-color-light);

        button {
            text-decoration: none;
            background-color: var(--audreys-color);
            color: var(--light-color);
        }
        a, button {
            color: var(--light-color);
        }
    }

    &:not(:last-child) {
        border-right: solid 1px var(--audreys-color);
    }

    .button-edit-inline:hover {
        background-color: var(--audreys-color-light);
    }
}

.edit-inline-logout {
    float: right;
    padding: 0 20px;

    a {
        display: block;
        color: #000;

        &:after {
            content: "Sign Out";
        }
    }

    i {
        margin-right: 5px;
    }
}

.inline_edit_trans.is-active {
    display: block;
    background-color: green !important;
    color: var(--light-color) !important;
}
