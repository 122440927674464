.typ-restaurant {
    padding-top: 75px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: var(--black);
    
    .container {
        padding-top: 60px;
        padding-bottom: 60px;
        
        @media (--min-desktop) {
            padding-top: 110px;
            padding-bottom: 110px;
        }
    }
}

.typ-restaurant--content {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    background: var(--light-color);
}

.typ-restaurant--data {
    padding: 22px 22px 0;

    @media (--min-desktop) {
        padding: 60px 40px 0;
    }
}

.typ-restaurant--title {
    font-family: var(--secondary-font);
    font-size: 30px;
    font-style: italic;
    letter-spacing: .5px;

    @media (--min-desktop) {
        margin-bottom: 10px;
        font-size: 42px;
    }
}

.typ-restaurant--subtitle {
    margin-bottom: 15px;
    font-family: var(--secondary-font);
    font-size: 25px;
    font-style: italic;
    letter-spacing: .5px;
    
    @media (--min-desktop) {
        margin-bottom: 30px;
        font-size: 38px;
    }
}

.typ-restaurant--text {
    margin-bottom: 30px;
    font-family: var(--primary-font);
    font-size: 16px;
    letter-spacing: .3px;
}

.typ-restaurant--link {
    margin-bottom: 60px;
    
    span {
        position: relative;
    }

    &:hover {
        span {
            color: var(--light-color);
        }

        &:before {
            z-index: 0;
        }
    }
}

.typ-restaurant--subtext {
    margin: 30px 0;
    font-family: var(--primary-font);
    font-size: 20px;
    font-weight: 500;
    letter-spacing: .4px;
    
    @media (--min-desktop) {
        margin: 60px 0 30px;
    }
}

.typ-restaurant--icon {
    display: flex;
    justify-content: space-between;
    background: var(--light-color);
}

.typ-restaurant--image {
    padding: 25px;
    flex: 0 1 calc(50% - 2px);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    svg {
        font-size: 100px;
        color: var(--light-color);

        @media (--min-desktop) {
            font-size: 130px;
        }
    }
}

