#contact_bookingDate, .date-booking .date-input {
    margin-bottom: 0px;
    border: none;
}
.dates-selector {
    display: flex;
    width: 100%;
    justify-content: space-around;
    border: 1px solid var(--black);

    &.black-theme {
        border: 1px solid var(--black);

        input {
            color: var(--black);
        }

        @include breakpoint(--min-desktop) {
            border-color: var(--black);

            input {
                color: var(--black);
            }
        }
    }

    @include breakpoint(--min-desktop) {
      border-color: var(--black)
    }

    .date-booking {
        width: 100%;
    }

    input {
        width: 100%;
        background: transparent;
        border: none;
        padding: .6rem;
        font-family: $default-font;
        color: $primary-color;
        font-size: 12px;
        font-size: rem(12);
        height: 38px;
        height: rem(38);
        text-align: center;

        &::-webkit-input-placeholder {
            color: var(--black);
            opacity: 1 !important;
        }

        &:-ms-input-placeholder {
            color: var(--black);
            opacity: 1 !important;
        }

        &:-moz-placeholder {
            color: var(--black);
            opacity: 1 !important;
        }

        &::-moz-placeholder {
            color: var(--black);
            opacity: 1 !important;
        }

        @include breakpoint(--min-desktop) {
            color: var(--black);
            font-size: 12px;
            font-size: rem(12);
            letter-spacing: .18px;
            letter-spacing: rem(0.18);

            &::-webkit-input-placeholder {
                color: var(--black);
                font-size: 10px;
                font-size: rem(10);
                font-weight: bold;
                letter-spacing: .18px;
                letter-spacing: rem(0.18);
                opacity: 1 !important;
            }

            &:-ms-input-placeholder {
                color: var(--black);
                font-size: 10px;
                font-size: rem(10);
                font-weight: bold;
                letter-spacing: .18px;
                letter-spacing: rem(0.18);
                opacity: 1 !important;
            }

            &:-moz-placeholder {
                color: var(--black);
                font-size: 10px;
                font-size: rem(10);
                font-weight: bold;
                letter-spacing: .18px;
                letter-spacing: rem(0.18);
                opacity: 1 !important;
            }

            &::-moz-placeholder {
                color: var(--black);
                font-size: 10px;
                font-size: rem(10);
                font-weight: bold;
                letter-spacing: .18px;
                letter-spacing: rem(0.18);
                opacity: 1 !important;
            }
        }
    }

    .black-theme::-webkit-input-placeholder {
        color: var(--black);

        @include breakpoint(--min-desktop) {
            color: var(--black);
        }
    }

    .black-theme:-ms-input-placeholder {
        color: var(--black);

        @include breakpoint(--min-desktop) {
            color: var(--black);
        }
    }

    .black-theme:-moz-placeholder {
        color: var(--black);

        @include breakpoint(--min-desktop) {
            color: var(--black);
        }
    }

    .black-theme::-moz-placeholder {
        color: var(--black);

        @include breakpoint(--min-desktop) {
            color: var(--black);
        }
    }
}

.flatpickr-calendar {
    .flatpickr-day {

        &.selected,
        &.selected:hover {
            background: var(--black);
            border-color: var(--black);
         }

         &:hover {
            border: 1px var(--black) solid;
            background: none;
          }
    }

    .today {
        border: 1px var(--black) solid;
        &:hover {
            border: 1px var(--black) solid;
            color: var(--numbers-date-picker);
         }
    }
}

.booking-date {
    position: relative;
    z-index: 200;
}
